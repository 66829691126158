<template>
  <div class="forgotPassword">
    <p class="title is-4" v-html="$t('Components.AccountControls.ForgotPassword.Main_Title')"></p>

    <div class="content">
      <p v-html="$t('Components.AccountControls.ForgotPassword.Text_Info')"></p>
    </div>

    <ui-base-input-field v-model="emailAddress" :labelForInput="false" :label="$t('Components.AccountControls.ForgotPassword.Label_EmailAddress')
      " :error="emailErrors.toString()" @input="delayTouch($v.emailAddress)" @blur="$v.emailAddress.$touch()" required />

    <div class="level is-mobile has-padding-top">
      <div class="level-left">
        <a class="has-icon" @click="backFunc">
          <span class="icon">
            <font-awesome-icon :icon="['fas', 'angle-left']" />
          </span>
          <span class="has-icon" v-html="$t('Form.Control.Back')"></span>
        </a>
      </div>
      <div class="level-right">
        <a @click="sendRequest" class="button is-success" v-bind:class="{ 'is-loading': isProcessing }" :disabled="Boolean($v.$error) || isProcessing" v-html="$t('Components.AccountControls.ForgotPassword.Button_SendCode')
          "></a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import loginProvider from '@/providers/login'
import { required, email } from 'vuelidate/lib/validators'
const touchMap = new WeakMap()

export default {
  name: 'component-forgot-password',

  props: {
    backFunc: {
      type: Function,
      default: () => { },
    },
  },

  data() {
    return {
      emailAddress: '',
      isProcessing: false,
    }
  },

  computed: {
    emailErrors() {
      const errors = []
      if (!this.$v.emailAddress.$dirty) return errors
      if (!this.$v.emailAddress.required) {
        errors.push(this.$t('Form.InputErrors.Required'))
      } else if (!this.$v.emailAddress.email) {
        errors.push(this.$t('Form.InputErrors.InvalidEmail'))
      }

      return errors
    },
  },

  /**
   * Form validation rules
   */
  validations: {
    emailAddress: {
      required,
      email,
    },
  },

  methods: {
    ...mapMutations('accountStore', ['setConfirmationToken']),

    delayTouch($v) {
      $v.$reset()
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v))
      }
      touchMap.set($v, setTimeout($v.$touch, 1000))
    },

    async sendRequest() {
      let self = this
      if (!this.isProcessing) {
        this.isProcessing = true
        this.$v.$touch()
        if (this.$v.$invalid) {
          this.submitStatus = 'ERROR'
          // //console.info(e)
          this.isProcessing = false
        } else {
          // do your submit logic here
          this.submitStatus = 'PENDING'
        }

        // Send request e-mail
        await loginProvider.methods
          .resetPassword(this.emailAddress, this.$i18n.locale)
          .then((response) => {
            self.$emit('sentSuccessfully')
            self.setConfirmationToken(response.data.ConfirmationToken)
          })
          .catch(() => { })
          .finally(() => {
            self = null
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
